import React, { useState } from 'react'
import scoreService from './services/scoreService'
import { DOMAIN_BASE } from './config'

function PokerCardImage({ handId, step }) {
  if (step === null) return <span>No cards yet</span>
  return (
    <img src={`${DOMAIN_BASE}/poker-hand-images/${handId}_${step}.png`} />
  )
}

export default function PokerResultsDialog() {
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [error, setError] = useState(null)

  const [step, setStep] = useState(null)
  const [hands, setHands] = useState([])

  const loadPokerProgress = async function () {
    setIsLoading(true)
    try {
      const { data } = await scoreService.getPokerProgress()
      setStep(data.step)
      setHands(data.hands)
    } catch(err) {
      console.error(err)
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error)
      } else {
        setError("Unable to complete request - please see admin")
      }
    }
    setIsLoading(false)
  }

  const onButtonClick = async () => {
    loadPokerProgress()
    setIsOpen(true)
  }

  return (
    <div>
      {isOpen && (
        <div className="dialog-wrapper">
          <div className="dialog-container">
            <h3>
              <button className="dialog-exit" onClick={() => setIsOpen(false)}>x</button>
              Poker Results
            </h3>
            {isLoading && <div>Loading...</div>}
            {!isLoading && (
              <table className="poker-hand-table">
                {hands.map((hand => (
                  <tr className="poker-hand-row">
                    <td className="poker-name">
                      {(step !== null && step >= 2) && (
                        <>
                          <span className="badge badge-primary">{hand.handRank}</span>{' '}
                        </>
                      )}
                      {hand.name}
                    </td>
                    <td className="poker-hand-image">
                      <PokerCardImage handId={hand.id} step={step} />
                      {(step !== null && step >= 2) && (
                        <>
                          <br />
                          {hand.handName}
                        </>
                      )}
                    </td>
                  </tr>
                )))}
              </table>
            )}
          </div>
        </div>
      )}
      <div className="poker-results-button-container">
        <button className="poker-results-button" onClick={() => onButtonClick()}>Poker!</button>
      </div>
    </div>
  )
}