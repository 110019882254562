import axios from 'axios'

const { DOMAIN_BASE } = require('./../config')

const teamService = {
  submit: (data) => {
    return axios.post(
      `${DOMAIN_BASE}/api/public/teams`,
      data
    ).then((response) => {
      return response.data.success
    })
  },
  getSignedUp: async () => {
    return axios.get(`${DOMAIN_BASE}/api/public/teams/signedUp`).then((response) => {
      return response.data.signedUp
    })
  }
}

export default teamService