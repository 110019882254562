import { useRef, useState } from 'react'

import teamService from '../../services/teamService'

const RequiredAsterisk = () => <span className="required">*</span>

const NewTeamForm = ({ signedUp, isFormReady }) => {
  const newTeamErrorsRef = useRef(null)
  const successAlertRef = useRef(null)
  
  const [isNewTeamLoading, setIsNewTeamLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errors, setErrors] = useState([])

  const [overrideFormOpen, setOverrideFormOpen] = useState(false)

  // Form values
  const [team, setTeam] = useState('')
  const [nickname, setNickname] = useState('') // HONEYPOT
  const [golfer1Name, setGolfer1Name] = useState('')
  const [golfer1Phone, setGolfer1Phone] = useState('')
  const [golfer1Email, setGolfer1Email] = useState('')
  const [golfer2Name, setGolfer2Name] = useState('')
  const [golfer2Phone, setGolfer2Phone] = useState('')
  const [golfer2Email, setGolfer2Email] = useState('')
  const [golfer3Name, setGolfer3Name] = useState('')
  const [golfer3Phone, setGolfer3Phone] = useState('')
  const [golfer3Email, setGolfer3Email] = useState('')
  const [golfer4Name, setGolfer4Name] = useState('')
  const [golfer4Phone, setGolfer4Phone] = useState('')
  const [golfer4Email, setGolfer4Email] = useState('')
  const [smsPermission, setSmsPermission] = useState(false)

  const submitNewTeam = (e) => {
    e.preventDefault()
    setIsNewTeamLoading(true)

    const data = {
      team, nickname,
      golfer1Name, golfer1Email, golfer1Phone,
      golfer2Name, golfer2Email, golfer2Phone,
      golfer3Name, golfer3Email, golfer3Phone,
      golfer4Name, golfer4Email, golfer4Phone,
      smsPermission
    }

    teamService.submit(data).then((success) => {
      setIsSuccess(success)
      window.scrollTo({ top: successAlertRef.current.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth' })
    }).catch((err) => {
      if (err.response.status === 400) {
        setErrors(err.response.data.errors)
        window.scrollTo({ top: newTeamErrorsRef.current.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth' })
      }
    }).finally(() => {
      setIsNewTeamLoading(false)
    })
  }

  const registrationClosed = signedUp >= 46
  const warningBackupsInBackground = (signedUp >= 36 && signedUp < 46)
  const warningBackups = warningBackupsInBackground && !overrideFormOpen
  const registrationOpen = signedUp < 36 || overrideFormOpen

  return (
    <>
      <h2>Tournament Sign Up</h2>
      {registrationClosed && (
        <div className="alert alert-warning">
          <strong>Team Registration Closed</strong><br />
          We have closed off team registration as we are now completely full for this year's tournament. Thank you to everyone that signed up! We look forward to seeing you at the tournament!
        </div>
      )}

      {warningBackups && (
        <div>
          <div className="alert alert-warning">
            <strong>We are full, but taking backups!</strong><br />
            We have reached the max of 36 teams, but are still taking backups in the event that someone can't make it.
          </div>
          <button type='button' className='btn btn-custom btn-lg' onClick={() => { setOverrideFormOpen(true) }} style={{ fontSize: 12 }}>
            I understand that teams are full. I want to register as a reserve
          </button>
        </div>
      )}

      {registrationOpen && (
        <div>
          {!isSuccess && (
            <div>
              <p>Please fill out the form below to sign up for the tournament.</p>
              <p>It is not required, but strongly encouraged to help us keep everyone informed by filling in you and your teammates email and phone number; we will send periodic updates/reminders about the tournament.</p>
            </div>
          )}
          <p>Checks payable to <span style={{ 'color': 'white', fontWeight: 'bold', textDecoration: 'underline' }}>Dennis Hutcherson Memorial Scholarship Fund</span>, or via Venmo: <a href="https://account.venmo.com/u/dennishutch_memorial" target="_blank" rel="noreferrer" style={{ fontWeight: 'bold', color: 'white', textDecoration: 'underline' }}>@dennishutch_memorial</a></p>

          {isSuccess && (
            <div className="alert alert-success" ref={successAlertRef}>
              <strong>Successfully {!warningBackupsInBackground ? 'signed up!' : 'registered as a reserve!'}</strong><br />
              {warningBackupsInBackground && <span>Someone will be in contact with you if a spot becomes available.</span>}
              {!warningBackupsInBackground && <span>We look forward to seeing you at the tournament.</span>}
            </div>
          )}

          {!isSuccess && isFormReady && (
            <form name='newGolfTeam' onSubmit={submitNewTeam}>
              {errors.length > 0 && (
                <div className='alert alert-danger' ref={newTeamErrorsRef}>
                  <strong>⚠️ Form Errors</strong><br />
                  Please double check errors below in red. If you are inputting phone numbers and/or emails make sure they are the correct format.<br /><br />
                  <ul>
                    <li>Email should have an "@" symbol followed by a domain (ex. @gmail.com)</li>
                    <li>Phone number should be a 10 digit number (without parentheses/dashes) starting with 3 digit area code</li>
                  </ul>
                </div>
              )}
              <label style={{ opacity: 0.00000000001, pointerEvents: 'none', height: '0' }}>
                <input
                  type='text'
                  id="team-nickname"
                  name="team-nickname"
                  className='form-control'
                  placeholder='Team Nickname'
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                  disabled={isNewTeamLoading}
                />
              </label>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label htmlFor="team">
                      <RequiredAsterisk />{' '}
                      Team Name:
                    </label>
                    <input
                      type='text'
                      id='team'
                      name='team'
                      className={`form-control ${errors.includes('team') ? 'hasError' : ''}`}
                      placeholder='Ex. Team Cougars or Team Smith'
                      value={team}
                      onChange={(e) => setTeam(e.target.value)}
                      disabled={isNewTeamLoading}
                    />
                  </div>
                </div>
              </div>

              {/* Golfer 1 */}
              <div className='row' style={{ borderBottom: '1px rgba(255, 255, 255, 0.5) solid', marginTop: '12px' }}>
                <div className='col-md-2'><strong>You:</strong></div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor="golfer1Name">
                      <RequiredAsterisk />{' '}
                      Name:
                    </label>
                    <input
                      type='text'
                      id='golfer1Name'
                      name='golfer1Name'
                      className={`form-control ${errors.includes('golfer1Name') ? 'hasError' : ''}`}
                      value={golfer1Name}
                      onChange={(e) => setGolfer1Name(e.target.value)}
                      disabled={isNewTeamLoading}
                      placeholder='First and last'
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor="golfer1Email">
                      <RequiredAsterisk />{' '}
                      Email:
                    </label>
                    <input
                      type='text'
                      id='golfer1Email'
                      name='golfer1Email'
                      className={`form-control ${errors.includes('golfer1Email') ? 'hasError' : ''}`}
                      value={golfer1Email}
                      onChange={(e) => setGolfer1Email(e.target.value)}
                      disabled={isNewTeamLoading}
                      placeholder="example@gmail.com"
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor="golfer1Phone">
                      <RequiredAsterisk />{' '}
                      Phone Number:
                    </label>
                    <input
                      type='number'
                      id='golfer1Phone'
                      name='golfer1Phone'
                      className={`form-control ${errors.includes('golfer1Phone') ? 'hasError' : ''}`}
                      value={golfer1Phone}
                      onChange={(e) => setGolfer1Phone(e.target.value)}
                      disabled={isNewTeamLoading}
                      maxLength="10"
                    />
                  </div>
                </div>
              </div>

              {/* Golfer 2 */}
              <div className='row' style={{ borderBottom: '1px rgba(255, 255, 255, 0.5) solid', marginTop: '12px' }}>
                <div className='col-md-2'>Teammate:</div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor="golfer2Name">
                      <RequiredAsterisk />{' '}
                      Name:
                    </label>
                    <input
                      type='text'
                      id='golfer2Name'
                      name='golfer2Name'
                      className={`form-control ${errors.includes('golfer2Name') ? 'hasError' : ''}`}
                      value={golfer2Name}
                      onChange={(e) => setGolfer2Name(e.target.value)}
                      disabled={isNewTeamLoading}
                      placeholder='First and last'
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor="golfer2Email">Email:</label>
                    <input
                      type='text'
                      id='golfer2Email'
                      name='golfer2Email'
                      className={`form-control ${errors.includes('golfer2Email') ? 'hasError' : ''}`}
                      value={golfer2Email}
                      onChange={(e) => setGolfer2Email(e.target.value)}
                      disabled={isNewTeamLoading}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor="golfer2Phone">Phone Number:</label>
                    <input
                      type='number'
                      id='golfer2Phone'
                      name='golfer2Phone'
                      className={`form-control ${errors.includes('golfer2Phone') ? 'hasError' : ''}`}
                      value={golfer2Phone}
                      onChange={(e) => setGolfer2Phone(e.target.value)}
                      disabled={isNewTeamLoading}
                      maxLength="10"
                    />
                  </div>
                </div>
              </div>

              {/* Golfer 3 */}
              <div className='row' style={{ borderBottom: '1px rgba(255, 255, 255, 0.5) solid', marginTop: '12px' }}>
                <div className='col-md-2'>Teammate:</div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor="golfer3Name">
                      <RequiredAsterisk />{' '}
                      Name:
                    </label>
                    <input
                      type='text'
                      id='golfer3Name'
                      name='golfer3Name'
                      className={`form-control ${errors.includes('golfer3Name') ? 'hasError' : ''}`}
                      value={golfer3Name}
                      onChange={(e) => setGolfer3Name(e.target.value)}
                      disabled={isNewTeamLoading}
                      placeholder='First and last'
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor="golfer3Email">Email:</label>
                    <input
                      type='text'
                      id='golfer3Email'
                      name='golfer3Email'
                      className={`form-control ${errors.includes('golfer3Email') ? 'hasError' : ''}`}
                      value={golfer3Email}
                      onChange={(e) => setGolfer3Email(e.target.value)}
                      disabled={isNewTeamLoading}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor="golfer3Phone">Phone Number:</label>
                    <input
                      type='number'
                      id='golfer3Phone'
                      name='golfer3Phone'
                      className={`form-control ${errors.includes('golfer3Phone') ? 'hasError' : ''}`}
                      value={golfer3Phone}
                      onChange={(e) => setGolfer3Phone(e.target.value)}
                      disabled={isNewTeamLoading}
                      maxLength="10"
                    />
                  </div>
                </div>
              </div>

              {/* Golfer 4 */}
              <div className='row' style={{ marginTop: '12px' }}>
                <div className='col-md-2'>Teammate:</div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label htmlFor="golfer4Name">
                      <RequiredAsterisk />{' '}
                      Name:
                    </label>
                    <input
                      type='text'
                      id='golfer4Name'
                      name='golfer4Name'
                      className={`form-control ${errors.includes('golfer4Name') ? 'hasError' : ''}`}
                      value={golfer4Name}
                      onChange={(e) => setGolfer4Name(e.target.value)}
                      disabled={isNewTeamLoading}
                      placeholder='First and last'
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor="golfer4Email">Email:</label>
                    <input
                      type='text'
                      id='golfer4Email'
                      name='golfer4Email'
                      className={`form-control ${errors.includes('golfer4Email') ? 'hasError' : ''}`}
                      value={golfer4Email}
                      onChange={(e) => setGolfer4Email(e.target.value)}
                      disabled={isNewTeamLoading}
                    />
                  </div>
                </div>
                <div className='col-md-3'>
                  <div className='form-group'>
                    <label htmlFor="golfer4Phone">Phone Number:</label>
                    <input
                      type='number'
                      id='golfer4Phone'
                      name='golfer4Phone'
                      className={`form-control ${errors.includes('golfer4Phone') ? 'hasError' : ''}`}
                      value={golfer4Phone}
                      onChange={(e) => setGolfer4Phone(e.target.value)}
                      disabled={isNewTeamLoading}
                      maxLength="10"
                    />
                  </div>
                </div>
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  id="smsPermission"
                  name="smsPermission"
                  className={`form-check-input ${errors.includes('smsPermission') ? 'is-invalid' : ''}`}
                  checked={smsPermission}
                  onClick={() => setSmsPermission(!smsPermission)}
                  disabled={isNewTeamLoading}
                />
                <label className={`form-check-label ${errors.includes('smsPermission') ? 'invalid-feedback' : ''}`} htmlFor="smsPermission">
                  I consent to receive (very few!) text messages from DHM Golf, such as announcements and reminders.
                </label>
              </div>
              <button type='submit' className='btn btn-custom btn-lg'>
                Enter Team{warningBackupsInBackground ? ' as backup' : ''}
              </button>
            </form>
          )}
        </div>
      )}
    </>
  )
}

export default NewTeamForm
