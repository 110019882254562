import React, { useEffect, useState } from 'react'

import NewTeamForm from './forms/newTeam'
import NewSponsorForm from './forms/newSponsor'

import teamService from '../services/teamService'

const Forms = () => {
  const [signedUp, setSignedUp] = useState(0)
  const [isFormReady, setIsFormReady] = useState(false)

  useEffect(() => {
    const getData = async () => {
      try {
        const signedUpCount = await teamService.getSignedUp()
        setSignedUp(signedUpCount)
        setIsFormReady(true)
      }
      catch(err) {
        console.error(err)
      }
    }
    getData()
  })

  return (
    <div id='contact'>
      <div className='container'>
        <div className='col-sm-12 col-md-7'>
          <div className='section-title'>
            <NewTeamForm signedUp={signedUp} isFormReady={isFormReady} />
          </div>
        </div>
        <div className='col-sm-12 col-md-1'>&nbsp;</div>
        <div className='col-sm-12 col-md-4'>
          <div className='section-title'>
            <NewSponsorForm isFormReady={isFormReady} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forms
