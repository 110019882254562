import axios from 'axios'

const { DOMAIN_BASE } = require('./../config')

const sponsorService = {
  submit: (data) => {
    return axios.post(
      `${DOMAIN_BASE}/api/public/sponsors`,
      data
    ).then((response) => {
      return response.data.success
    })
  }
}

export default sponsorService