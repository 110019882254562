import React, { useRef, useState } from "react"

import sponsorService from "../../services/sponsorService"

const RequiredAsterisk = () => <span className="required">*</span>

function NewSponsorForm({ isFormReady }) {
  const newSponsorErrorsRef = useRef(null)
  const [isNewSponsorLoading, setIsNewSponsorLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [errors, setErrors] = useState([])

  // Form values
  const [name, setName] = useState('')
  const [nickname, setNickname] = useState('') // HONEYPOT
  const [contactName, setContactName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [level, setLevel] = useState('')

  const sponsorLevels = [
    { value: 'gold', label: 'Gold - $500 (Sole hole sponsorship)' },
    { value: 'silver', label: 'Silver - $300 (Shared hole with 1 other sponsor)' },
    { value: 'bronze', label: 'Bronze - $200 (Shared hole with 2 or more other sponsors)' }
  ]

  const submitNewSponsor = (e) => {
    e.preventDefault()
    setIsNewSponsorLoading(true)

    const data = {
      name, nickname, contactName, email, phone, address, level
    }

    sponsorService.submit(data).then((success) => {
      setIsSuccess(success)
    }).catch((err) => {
      if (err.response.status === 400) {
        setErrors(err.response.data.errors)
        window.scrollTo({ top: newSponsorErrorsRef.current.getBoundingClientRect().top + window.pageYOffset - 100, behavior: 'smooth' })
      }
    }).finally(() => {
      setIsNewSponsorLoading(false)
    })
  }

  return (
    <>
      <h2 id="sponsorSignUpHeader">Sponsor Sign Up</h2>
      {!isSuccess && (
        <>
          <p>Please fill out the form below and someone will be in touch with you about becoming a sponsor.</p>
          <p>For questions please contact Connie: <a href="tel:16604412302" style={{ color: 'white', fontWeight: 'bold', textDecoration: 'underline', display: 'inline-block' }}>660-441-2302</a></p>
        </>
      )}
    
      {isSuccess && (
        <div className="alert alert-success"><strong>Successfully submitted!</strong><br />Someone will be in contact with you soon.</div>
      )}

      {!isSuccess && isFormReady && (
        <form name='newSponsor' onSubmit={submitNewSponsor}>
          {errors.length > 0 && (
            <div className='alert alert-danger' ref={newSponsorErrorsRef}>
              <strong>⚠️ Form Errors</strong><br />
              Please double check errors below in red. All fields are required.<br /><br />
              <ul>
                <li>Email must have a "@" symbol followed by a domain (ex. @gmail.com)</li>
                <li>Phone number should be a 10 digit number (without parentheses/dashes) starting with 3 digit area code</li>
              </ul>
            </div>
          )}
          <div className='form-group' style={{ opacity: 0.00000000001, pointerEvents: 'none', height: '0' }}>
            <label htmlFor="nickname">Nickname:</label>
            <input
              type='text'
              id='nickname'
              name='nickname'
              className={`form-control ${errors.includes('nickname') ? 'hasError' : ''}`}
              placeholder='A funny nickname'
              value={name}
              onChange={(e) => setNickname(e.target.value)}
              disabled={isNewSponsorLoading}
            />
          </div>
        
          <div className='form-group'>
            <label htmlFor="name">
              <RequiredAsterisk />{' '}
              Sponsor Name:
            </label>
            <input
              type='text'
              id='name'
              name='name'
              className={`form-control ${errors.includes('name') ? 'hasError' : ''}`}
              placeholder='Ex. ABC Company'
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isNewSponsorLoading}
            />
          </div>

          <div className='form-group'>
            <label htmlFor="contactName">
              <RequiredAsterisk />{' '}
              Contact Name:
            </label>
            <input
              type='text'
              id='contactName'
              name='contactName'
              className={`form-control ${errors.includes('contactName') ? 'hasError' : ''}`}
              placeholder='Your first and last name'
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              disabled={isNewSponsorLoading}
            />
          </div>

          <div className='form-group'>
            <label htmlFor="email">
              <RequiredAsterisk />{' '}
              Email:
            </label>
            <input
              type='text'
              id='email'
              name='email'
              className={`form-control ${errors.includes('email') ? 'hasError' : ''}`}
              value={email}
              placeholder="example@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
              disabled={isNewSponsorLoading}
            />
          </div>

          <div className='form-group'>
            <label htmlFor="email">
              <RequiredAsterisk />{' '}
              Phone Number:
            </label>
            <input
              type='number'
              id='phone'
              name='phone'
              className={`form-control ${errors.includes('phone') ? 'hasError' : ''}`}
              value={phone}
              placeholder="1234567890"
              onChange={(e) => setPhone(e.target.value)}
              disabled={isNewSponsorLoading}
              maxLength="10"
            />
          </div>

          <div className='form-group'>
            <label htmlFor="email">
              <RequiredAsterisk />{' '}
              Address:
            </label>
            <textarea
              rows="3"
              type='text'
              id='address'
              name='address'
              className={`form-control ${errors.includes('address') ? 'hasError' : ''}`}
              value={address}
              placeholder="123 Main Street"
              onChange={(e) => setAddress(e.target.value)}
              disabled={isNewSponsorLoading}
            />
          </div>

          <div className='form-group'>
            <label htmlFor="level">
              <RequiredAsterisk />{' '}
              Level:
            </label>
            <select
              id='level'
              name="level"
              className={`form-control ${errors.includes('level') ? 'hasError' : ''}`}
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              <option>Please select a level</option>
              {sponsorLevels.map((level) => <option key={level.value} value={level.value}>{level.label}</option>)}
            </select>
          </div>

          <button type='submit' className='btn btn-custom btn-lg'>Submit</button>
          <div style={{ display: 'none' }}>Another Test</div>
        </form>
      )}
    </>
  )
}

export default NewSponsorForm