export const Tournament = (props) => {
  return (
    <div id='tournament' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <br /><br /><br /><br />
          <h2>Golf Tournament</h2>
        </div>
        <div className='row'>
          {/* {props.data
            ? props.data.map((d, i) => (
                <div className='col-xs-12 col-sm-12'>
                  <div className='row' key={`${d.title}-${i}`}>
                    <div className='col-xs-3 col-md-3 tournament-section' style={{ textAlign: 'right' }}>
                      <i className={`${d.icon} dhm-icon`} style={{ position: 'relative', top: 20 }}></i>
                    </div>
                    <div className='col-xs-8 col-md-3' style={{ textAlign: 'left' }}>
                      <h3>{d.title}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                </div>
              ))
            : 'Loading...'} */}

          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.title}-${i}`} className='col-xs-12 col-md-3 tournament-section'>
                  {' '}
                  <i className={`${d.icon} dhm-icon`}></i>
                  <h3>{d.title}</h3>
                  <p>{d.text}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
        <div className='row text-center'>
          <div className='col-xs-12'>
            <br />
            <h3>Sponsorships</h3>
            <p>Sponsorships include: signage at tee box, recognition on website, social media and printed marketing materials</p>
          </div>
        </div>
        <div className='row text-center'>
          <div className='col-sm-4'>
            <h3>Gold</h3>
            <p>$500<br />Sole hole sponsorship<br />$100 team discount</p>
          </div>
          <div className='col-sm-4'>
            <h3>Silver</h3>
            <p>$300<br />Shared hole<br />(with 1 other sponsor)</p>
          </div>
          <div className='col-sm-4'>
            <h3>Bronze</h3>
            <p>$200<br />Shared hole<br />(with 2 or more other sponsors)</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <br />
            <a
              href='/docs/flyer-2024.pdf'
              className='btn btn-custom btn-lg btn-flyer-cta page-scroll'
              target="_blank"
              style={{ position: 'relative', top: 6 }}
            >
              Download the Flyer
            </a>{' '}
            <a
              href='#sponsorSignUpHeader'
              className='btn btn-custom-green btn-lg page-scroll'
            >
              Become a Sponsor
            </a>{' '}
          </div>
        </div>
      </div>
    </div>
  )
}
