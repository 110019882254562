import axios from 'axios'

const { DOMAIN_BASE } = require('./../config')

const scoreService = {
  getScores: async (uuid) => {
    return axios.get(
      `${DOMAIN_BASE}/api/public/scores/teams/${uuid}`
    ).then((response) => {
      return response
    })
  },
  // Note: 
  updateScore: async (uuid, holeId, score) => {
    return axios.post(
      `${DOMAIN_BASE}/api/public/scores/teams/${uuid}/score`, {
        holeId, score
      }
    ).then((response) => {
      return response
    })
  },
  getPokerProgress: async () => {
    return axios.get(
      `${DOMAIN_BASE}/api/public/poker-progress`
    ).then((response) => {
      return response
    })
  }
}

export default scoreService