export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            {' '}
            <img src='img/dennis/lake.JPG' className='img-responsive' alt='' />{' '}
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>About</h2>
              <div>{props.data && props.data.paragraphs && (
                <div>
                  {props.data.paragraphs.map((paragraph, i) => <p key={i}>{paragraph}</p>)}
                </div>
              )}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
