import React, { useEffect, useState } from "react"
import { useParams, useLocation } from "react-router-dom"

import scoreService from "./services/scoreService"

import './Score.css'
import PokerResultsDialog from "./PokerResults"

function SelectScore({ par, holeId, score, contextUuid, scoresLocked, isScoring }) {
  const bogey = par + 1

  const updateScore = async (uuid, holeId, score) => {
    if (!isScoring) {
      return alert('Unable to update score - you are not in scorekeeping mode')
    }

    try {
      await scoreService.updateScore(uuid, holeId, score)
      window.location.reload()
    } catch (err) {
      console.log(err)
      if (err.response && err.response.data && err.response.data.error) {
        alert(err.response.data.error)
      }
    }
  }

  let options = Array.from(Array(bogey + 1).keys()).map(option => {
    let label = ''
    if (option === par) label = 'Par'
    else if (option === par+1) label = 'Bogey'
    else if (option === par-1) label = 'Birdie'
    else if (option === par-2) label = 'Eagle'
    else if (option === par-3) label = 'Albatross'
    else label = 'Ace'

    return (
      <option key={option} value={option === null ? null : option}>
        {option === 0 ? '' : `${option} ${label}`}
      </option>
    )
  })

  return (
    <select className="form-control" value={score || '0'} disabled={!contextUuid || scoresLocked || !isScoring} onChange={(e) => { updateScore(contextUuid, holeId, parseInt(e.target.value)) }}>
      {options}
    </select>
  )
}

function Score() {
  const { pathname } = useLocation()
  const { uuid } = useParams()

  const isScoring = !pathname?.includes('/spectator/')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [scoresLocked, setScoresLocked] = useState(false)

  const [myTeam, setMyTeam] = useState(null)
  const [partnerTeam, setPartnerTeam] = useState(null)
  const [startHole, setStartHole] = useState(null)
  const [holes, setHoles] = useState([])
  const [myScore, setMyScore] = useState(null)
  const [partnerScore, setPartnerScore] = useState(null)
  const [myCaptain, setMyCaptain] = useState(null)
  const [partnerCaptain, setPartnerCaptain] = useState(null)

  useEffect(() => {
    async function refreshScores(uuid) {
      setLoading(true)
      try {
        const { data } = await scoreService.getScores(uuid)
        setMyTeam(data.myTeam)
        setPartnerTeam(data.partnerTeam)
        setHoles(data.holes)
        setStartHole(data.startHole)
        setMyScore(data.myScore)
        setMyCaptain(data.myCaptain)
        setPartnerCaptain(data.partnerCaptain)
        setPartnerScore(data.partnerScore)
        setScoresLocked(data.scoresLocked)
      } catch(err) {
        console.error(err)
        if (err.response && err.response.data && err.response.data.error) {
          setError(err.response.data.error)
        } else {
          setError("Unable to complete request - please see admin")
        }
      }
      setLoading(false)
    }
    refreshScores(uuid)
  }, [uuid])

  if (error) {
    return <div>{error}</div>
  }

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className="container-fluid" style={{ paddingRight: 0, paddingLeft: 0 }}>
      <div className="score-header">
        <table className="score-table">
          <tbody>
            <tr>
              <td className="score-captains">
                <div className="score-teamname">{myTeam}</div>
                <div className="score-captainName">{myCaptain}</div>
                <div className="score-value">{`${myScore > 0 ? '+' : ''}${myScore}`}</div>
              </td>
              <td className="score-tournament">
                DHM Golf Tournament<br />
                {scoresLocked && (
                  <span className="badge badge-primary" title="Refresh to try again" style={{ backgroundColor: 'red', marginTop: 6 }}>SCORING LOCKED</span>
                )}
                <PokerResultsDialog />
              </td>
              <td className="score-captains">
                <div className="score-teamname">{partnerTeam}</div>
                <div className="score-captainName">{partnerCaptain}</div>
                <div className="score-value">{`${partnerScore > 0 ? '+' : ''}${partnerScore}`}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <table className="table table-striped score-valueTable">
        <tbody>
          {holes.map(hole => (
            <tr key={hole.number}>
              <td className="text-center">
                <SelectScore
                  par={hole.par}
                  score={hole.myScore}
                  holeId={hole.id}
                  contextUuid={uuid}
                  scoresLocked={scoresLocked}
                  isScoring={isScoring}
                />
              </td>
              <td className="text-center">
                <strong>Hole #{hole.number}</strong> ({hole.par})<br />
                <span style={{ fontSize: 10, color: '#111' }}>{hole.black}</span>{' '}<span style={{ fontSize: 10, color: 'blue' }}>{hole.blue}</span>{' '}<span style={{ fontSize: 10, color: '#c0c0c0', }}>{hole.white}</span>{' '}<span style={{ fontSize: 10, color: 'red', }}>{hole.red}</span><br />
                {(hole.number === startHole) && (
                  <em>Start</em>
                )}
              </td>
              <td className="text-center">
                <SelectScore
                  par={hole.par}
                  score={hole.partnerScore}
                  holeId={hole.id}
                  scoresLocked={scoresLocked}  
                  isScoring={isScoring}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Score